import { call, put, select } from 'redux-saga/effects';

import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import getDecision from 'app/lib/analytics/Optimizely/getDecision';
import { translateBackendErrorToMessage } from 'redux/modules/Shared/BackendParser';

import {
  setFormPristine,
  setValidationDirectDebitForm,
  sectionSubmissionFailed,
} from '../actions';
import {
  getAboutYouFormFields,
  getBillingAddressState,
  getDirectDebitFormError,
  getDirectDebitFormFields,
  getBillingAddressSame,
} from '../api';
import {
  BANK_ACCOUNT_VERIFICATION_ERROR,
  DIRECT_DEBIT_SECTION,
} from '../constants';
import { aboutYouSection, directDebitSection } from '../form';
import {
  postBillingAddress,
  postDirectDebitToken,
  putDirectDebitDetails,
} from '../services';
import { validationDirectDebitSection } from '../validation/directDebitSection';
import { EntryFormApi } from '../../EntryForm/api';

const {
  DIRECT_DEBIT_NAME,
  DIRECT_DEBIT_SORT_CODE,
  DIRECT_DEBIT_ACCOUNT_NUMBER,
} = directDebitSection;

const isBankValidationError = (error) => {
  if (!error.response) {
    return false;
  }

  const { status, data } = error.response;

  if (status === 400 && data.type === 'BankAccountOwnershipNotVerified') {
    return true;
  }

  return false;
};

function* formValidate() {
  const {
    [aboutYouSection.ABOUT_YOU_NAME_LAST]: accountHolderName,
    [aboutYouSection.ABOUT_YOU_ADDITIONAL_NAME_LAST]: additionalAccountHolderName,
  } = yield select(getAboutYouFormFields);
  const fields = yield select(getDirectDebitFormFields);
  const billingAddress = yield select(getBillingAddressState);

  const props = {
    accountHolderName,
    additionalAccountHolderName,
    billingAddress,
  };
  const { valid, fields: validatedFields } = validationDirectDebitSection(
    fields,
    props
  );

  yield put(setValidationDirectDebitForm(valid, validatedFields));
}

function* submitDebitCardDetails() {
  const {
    [DIRECT_DEBIT_NAME]: name,
    [DIRECT_DEBIT_SORT_CODE]: sortCode,
    [DIRECT_DEBIT_ACCOUNT_NUMBER]: accountNumber,
  } = yield select(getDirectDebitFormFields);

  try {
    const tokenResponse = yield call(
      postDirectDebitToken,
      accountNumber,
      sortCode
    );

    const responseData = tokenResponse?.data;
    const { token } = responseData;
    yield call(putDirectDebitDetails, name, sortCode, accountNumber, token);
  } catch (error) {
    const formError = yield select(getDirectDebitFormError);
    const { variation } = yield call(
      getDecision,
      features.SKIP_DD_VERIFICATION
    );

    const skipVerification =
      variation === variations[features.SKIP_DD_VERIFICATION].ON ||
      formError === BANK_ACCOUNT_VERIFICATION_ERROR;

    if (skipVerification && isBankValidationError(error)) {
      return;
    }

    const message = translateBackendErrorToMessage(error);
    Sentry.log(error, ERRORS.UPDATE_ABOUT_YOU_NAME_EMAIL);
    return yield put(sectionSubmissionFailed(message));
  }
}

function* submitAddress() {
  const billingAddress = yield select(getBillingAddressState);
  const billingAddressSame = yield select(getBillingAddressSame);
  const supplyAddress = yield select(EntryFormApi.supplyAddress);

  try {
    yield call(
      postBillingAddress,
      billingAddressSame ? supplyAddress : billingAddress
    );
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    Sentry.log(error, ERRORS.UPDATE_YOUR_DETAILS_ADDRESS);
    return yield put(sectionSubmissionFailed(message));
  }
}

function* formSubmit() {
  yield put(setFormPristine(DIRECT_DEBIT_SECTION, false));
  const cardDetailsResponce = yield call(submitDebitCardDetails);
  const submitAddressResponse = yield call(submitAddress);
  return [cardDetailsResponce, submitAddressResponse];
}

export {
  isBankValidationError,
  formValidate,
  submitDebitCardDetails,
  submitAddress,
  formSubmit,
};
