import { Basket } from 'app/components/modules/YourDetails/Basket';
import { STORE_NAME } from 'app/redux/modules/OrderSummary/constants';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { connector } from 'redux/utils/connector';

import {
  transformAddOns,
  transformServices,
  getProductTotals,
  getBasketItems,
} from '../presentation';

const BasketContainer = connector(STORE_NAME, (state) => {
  const application = OrderSummaryApi.getApplication(state);

  const services = transformServices(application);
  const addOns = transformAddOns(application.products, state);

  const { totalPrice, discountedTotalPrice } = getProductTotals(
    services,
    addOns
  );

  return {
    services: getBasketItems(services),
    addOns: getBasketItems(addOns),
    totalPrice,
    discountedTotalPrice:
      discountedTotalPrice.value === totalPrice.value
        ? null
        : discountedTotalPrice,
  };
})(Basket);

export { BasketContainer };
